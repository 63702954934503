import { createRouter, createWebHistory } from "vue-router";
import InsertPhone from "../components/AccessComponents/LoginComponents/InsertPhone.vue";
import { useUiStore } from "../stores/ui";
import { useUserStore } from "../stores/user";
import { datadogRum } from "@datadog/browser-rum";
import { CLIENT_NAME, CLIENT_VERSION } from "../constants/utils";

/* UTILITY ROUTE*/
const Access = () => import("../views/Utils/Access.vue");
const LoginAccess = () => import("@/views/Utils/LoginAccess.vue");
const RegisterAccess = () => import("@/views/Utils/RegisterAccess.vue");

/* CONTENT ROUTE*/
const Home = () => import("../views/Home.vue");
const YourTrip = () => import("../views/YourTrip.vue");
const Billing = () => import("../views/Billing.vue");
const ManageUser = () => import("../views/ManageUser/ManageUser.vue");
const AddCompany = () => import("../views/ManageUser/AddCompany.vue");
const AddUser = () => import("../views/ManageUser/AddUser.vue");
const OTPForm = () => import("../components/AccessComponents/OTPForm.vue");
const InsertCredit = () =>
  import("../components/AccessComponents/InsertCredit.vue");
const AddCreditCard = () => import("../components/Utils/AddCreditCard.vue");
const NotFound = () => import("../views/Utils/NotFound.vue");
const TripDetailComponent = () =>
  import("../components/TripComponents/TripDetailComponent.vue");
const TripEditComponent = () =>
  import("../components/TripComponents/TripEditComponent.vue");
const TripHistoryPage = () => import("../views/TripHistoryPage.vue");
const ProfileIndex = () => import("../views/Profile/ProfileIndex.vue");
const ProfileAccounting = () =>
  import("../views/Profile/ProfileAccounting.vue");
const ProfileReferall = () => import("../views/Profile/ProfileReferall.vue");
const Partners = () => import("../views/Profile/Partners.vue");

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/access",
      name: "access",
      component: Access,
      meta: {
        guest: true,
      },
      async beforeEnter(route, from, next) {
        const store = useUserStore();
        if (await store.validateSession()) {
          next({
            path: "/",
          });
          return;
        }
        next();
      },
    },
    {
      path: "/access/login",
      name: "loginAccess",
      component: LoginAccess,
      meta: {
        guest: true,
      },
      async beforeEnter(route, from, next) {
        const store = useUserStore();
        if (!store.registration && !store.userInfo) {
          next({
            path: "/access",
          });
          return;
        }
        if (await store.validateSession()) {
          next({
            path: "/",
          });
          return;
        }
        next();
      },
    },
    {
      path: "/signup/:masterCode?",
      name: "signup",
      redirect: (to) => {
        return `/access/register/${to.params.masterCode}`;
      },
    },
    {
      path: "/access/register/:masterCode?",
      name: "registerAccess",
      component: RegisterAccess,
      meta: {
        guest: true,
      },
      async beforeEnter(route, from, next) {
        const store = useUserStore();
        if (route.params.masterCode) {
          store.setMasterCode(route.params.masterCode as string);
        }
        if (!store.getToken()) {
          next({
            path: "/",
          });
          return;
        }
        if (!store.registration && !store.userInfo) {
          await store.validateSession();
        }
        next();
      },
    },
    {
      path: "/access/otp",
      name: "validateOTP",
      component: OTPForm,
      meta: {
        guest: true,
      },
      async beforeEnter(route, from, next) {
        const store = useUserStore();
        if (!store.registration && !store.userInfo) {
          next({
            path: "/access",
          });
          return;
        }
        next();
      },
    },
    {
      path: "/access/insert-phone",
      name: "insertPhone",
      meta: {
        doNotCheckPhone: true,
      },
      component: InsertPhone,
    },
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/trips",
      name: "trips",
      component: YourTrip,
    },
    {
      path: "/trips/history",
      name: "tripsHistoryList",
      component: TripHistoryPage,
    },
    {
      path: "/billing",
      name: "billing",
      component: Billing,
    },
    {
      path: "/manage-user",
      name: "ManageUser",
      component: ManageUser,
    },
    {
      path: "/manage-user/add-agency",
      name: "AggiungiAzienda",
      component: AddCompany,
    },
    {
      path: "/manage-user/add-user",
      name: "AggiungiUtente",
      component: AddUser,
    },
    {
      path: "/user/credit",
      name: "userCredit",
      component: InsertCredit,
    },
    {
      path: "/user/add-credit-card",
      name: "addCreditCard",
      component: AddCreditCard,
    },
    {
      path: "/trip/detail/:tripId/:companyId",
      name: "tripDetail",
      component: TripDetailComponent,
    },
    {
      path: "/trip/edit/:tripId/:companyId",
      name: "tripEdit",
      component: TripEditComponent,
    },
    {
      path: "/profile",
      name: "profileIndex",
      component: ProfileIndex,
    },
    {
      path: "/profile/accounting",
      name: "profileAccounting",
      component: ProfileAccounting,
    },
    {
      path: "/profile/referall",
      name: "profileReferall",
      component: ProfileReferall,
    },
    {
      path: "/partners/:partner?",
      name: "partners",
      component: Partners,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "notFound",
      component: NotFound,
    },
  ],
});

router.beforeEach(async (route, from, next) => {
  const userStore = useUserStore();
  if (route.query.mastercode) {
    userStore.setMasterCode(route.query.mastercode as string);
  }
  if (!route.meta.guest) {
    // check user session
    if (await userStore.validateSession()) {
      // toggle loader for lazy loaded components
      if (typeof route.matched[0]?.components?.default === "function") {
        const uiStore = useUiStore();
        uiStore.toggleLoading(true);
      }

      // if no phone force user to add it
      if (!userStore.userInfo?.phone && route.meta.doNotCheckPhone !== true) {
        next({
          path: "/access/insert-phone",
        });
        return;
      }

      // else redirect user to the desired route
      next();
      datadogRum.startView({
        name: route.name?.toString(),
        service: CLIENT_NAME,
        version: CLIENT_VERSION,
      });

      return;
    }

    // pending user
    if (userStore.isPending(userStore.getToken())) {
      next({
        path: "/access/register",
        params: { nextUrl: route.fullPath },
      });
      return;
    }

    next({ path: "/access", params: { nextUrl: route.fullPath } }); // unauthenticated user
    return;
  }
  next();
});

router.beforeResolve((to, from, next) => {
  const uiStore = useUiStore();
  uiStore.toggleLoading(false);
  next();
});

export default router;
