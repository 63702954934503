import Api, { parseApiResponse } from "./Api";
import type { CurrencyExchange } from "@/models/currency/currencyExchange";

export default class CurrencyApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL + "currencies";
  v2BaseApiUrl = import.meta.env.VITE_API_URL + "v2/currencies";

  async getGbpEurExchangeRate() {
    const res = await this.get(this.baseApiUrl, "/exchangerate/gbp");
    const data = await res.text();
    if (res.ok) {
      return parseFloat(data);
    }
    throw new Error(data);
  }

  async getExchangeRates() {
    return parseApiResponse<Array<CurrencyExchange>>(
      await this.get(this.v2BaseApiUrl, "/exchangerates"),
    );
  }
}
