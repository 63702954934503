import type { PiniaPluginContext } from "pinia";
import { CLIENT_NAME, CLIENT_VERSION } from "../../constants/utils";
import {
  TRIP_TO_INSERT_INITIAL_VALUE,
  type Viaggio,
} from "../../models/trip/trip";
import type { useTripStore } from "../trip";

export function TripStorePlugin({
  store,
}: PiniaPluginContext<"trip", ReturnType<typeof useTripStore>>) {
  if (store.$id === "trip") {
    if (!store.tripToInsert) {
      store.tripToInsert =
        store.getLocalTrip() ??
        ({ ...TRIP_TO_INSERT_INITIAL_VALUE } as Viaggio);

      const isMigration = store.tripToInsert.version !== CLIENT_VERSION;
      if (isMigration) {
        store.resetLocalStorage();
        store.tripToInsert = { ...TRIP_TO_INSERT_INITIAL_VALUE } as Viaggio;
      }

      const extras = store.tripToInsert.spieg_extra?.split(",");
      if (extras) {
        store.baggage =
          Number(
            extras
              .find((n) => n.includes("Bagagli da stiva"))
              ?.replace("Bagagli da stiva: ", ""),
          ) || 0;

        store.luggage =
          Number(
            extras
              .find((n) => n.includes("Bagagli a mano"))
              ?.replace("Bagagli a mano: ", ""),
          ) || 0;

        const babySeat = extras
          .find((n) => n.includes("Seggiolino"))
          ?.replace("Seggiolino: ", "")
          .trim();
        if (babySeat) {
          store.babySeats = [babySeat];
        }

        store.extraAssistance = extras.find((n) =>
          n.includes("Assistenza speciale"),
        )
          ? true
          : false;
      }
    }

    // if (!store.preventivo) {
    //   store.recoverPreventivoFromLocal();
    // }

    if (!store.rideType) {
      store.rideType = store.getLocalRideType();
    }

    store.$subscribe(() => {
      store.saveLocally();
    });
  }
}
