<script lang="ts">
import { Form as ValidatorForm, Field } from "vee-validate";
import { useToast } from "vue-toastification";
import countries_prefixes from "../../../constants/countries_prefixes";
import type { Registration } from "../../../models/user/user";
import { userInfoToRegistrationRequest } from "../../../models/utils";
import { useUiStore } from "../../../stores/ui";
import { useUserStore } from "../../../stores/user";
import AppFormField from "../../Utils/AppFormField.vue";
import ButtonComponent from "../../Utils/ButtonComponent.vue";
import DropdownComponent from "../../Utils/Dropdown.vue";

export default {
  name: "InsertPhone",
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
      uiStore: useUiStore(),
      toast: useToast(),
      user: userStore?.registration ?? ({} as Registration),
    };
  },
  data() {
    return {
      prefixFields: {
        dropdownText: "dropdownText",
        selectedText: "selectedText",
        value: "dial_code",
      },
      prefixes: countries_prefixes.map((p) => ({
        ...p,
        dropdownText: `(${p.dial_code}) ${p.name}`,
        selectedText: `${p.dial_code}`,
      })),
      telephone: "",
      prefix: "",
    };
  },
  methods: {
    async updateUser() {
      try {
        this.uiStore.toggleLoading(true);
        await this.userStore.userApi.updateRegistration(
          userInfoToRegistrationRequest(
            {
              ...this.userStore.userInfo!,
              phone: this.prefix + this.telephone,
            },
            {
              idcliente: this.userStore.userInfo?.payload.idCliente.toString(),
              prefix: this.prefix,
            },
          ),
        );
        const { accessToken, user } =
          await this.userStore.userApi.validateSession();
        this.userStore.userInfo = user;
        this.userStore.accessToken = accessToken;
        this.$router.push("/");
      } catch (error) {
        this.toast.error((error as Error).message);
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },
  },
  components: {
    AppFormField,
    ValidatorForm,
    ButtonComponent,
    DropdownComponent,
    Field,
  },
  mounted() {
    if (this.userStore.userInfo?.phone) {
      this.$router.push("/");
    }
  },
};
</script>

<template>
  <section class="flex h-screen">
    <img
      class="absolute top-10 left-10 w-56"
      src="@/assets/imgs/Logo-access.png"
      alt=""
    />
    <div
      class="w-3/4 lg:w-1/2 h-fit flex flex-col m-auto z-50 bg-limolane-bg-blue drop-shadow-2xl justify-between shadow-2xl"
    >
      <h1
        class="mx-20 text-center text-3xl lg:text-4xl font-semibold pt-6 pb-6 xl:pt-8 text-white"
      >
        {{ $t("InsertPhoneToContinue") }}
      </h1>
      <ValidatorForm
        @submit="updateUser"
        ref="form"
        class="flex flex-col mx-20 h-full"
      >
        <div class="w-full mt-8 flex flex-row gap-4">
          <div class="flex w-1/4 flex-col relative group">
            <Field
              name="prefix"
              v-model="prefix"
              v-slot="{ field, meta }"
              rules="required|prefix"
            >
              <DropdownComponent
                :label="$t('EnterPagePrefix')"
                :placeholder="'...'"
                :options="prefixes"
                :fields="prefixFields"
                :clearFunction="false"
                :enableFiltering="true"
                v-bind="field"
                :isInvalid="meta.valid === false && meta.touched"
              />
            </Field>
          </div>
          <div class="flex w-3/4 flex-col relative group">
            <label
              class="px-1 text-[#919EAB] font-medium text-sm absolute -top-3 left-3 bg-limolane-bg-blue group-focus-within:text-[#ACB7EC] transition-colors"
              for="userPhone"
              >{{ $t("PassengerPhone") }}</label
            >
            <AppFormField
              type="text"
              cssClass="w-full p-2 bg-transparent appearance-none border-2 border-[#919eab3d] placeholder:text-[#919EAB] text-white outline-0 focus:border-[#ACB7EC] transition-colors"
              name="userPhone"
              ref="userPhone"
              :placeholder="$t('PassengerPhoneInsert')"
              v-model="telephone"
              rules="required|validPhoneWithoutPrefix"
            />
          </div>
        </div>
        <div class="flex justify-center mt-12">
          <ButtonComponent submit :label="$t('ButtonContinue')" />
        </div>
        <div class="flex justify-center mt-2 mb-8">
          <ButtonComponent
            @on-click="userStore.logout()"
            :label="$t('Logout')"
          />
        </div>
      </ValidatorForm>
    </div>
  </section>
</template>

<style scoped>
.checkbox-privacy input[type="checkbox"] {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox-privacy input[type="checkbox"]:checked::before {
  content: "✓";
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #643d85;
}

section {
  background-image: url("@/assets/imgs/Group-133.jpg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
</style>
