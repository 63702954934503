import { createApp } from "vue";
import { createPinia } from "pinia";

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import App from "./App.vue";
import router from "./router";

import "./assets/main.css";

import { registerLicense } from "@syncfusion/ej2-base";

import { i18n } from "./utils/i18n";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faPhone,
  faCircleXmark,
  faEllipsisVertical,
  faStar,
  faLocationDot,
  faFlagCheckered,
  faCar,
  faClock,
  faHamburger,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import UiStorePlugin from "./stores/plugins/ui";
import { TripStorePlugin } from "./stores/plugins/trip";
import RouterStorePlugin from "./stores/plugins/router";
import JsonExcel from "vue-json-excel3";
import { datadogRum } from "@datadog/browser-rum";
import IdlePlugin from "./plugins/idle";
import { useUiStore } from "./stores/ui";
import { useUserStore } from "./stores/user";
import { useTripStore } from "./stores/trip";

registerLicense(
  "ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RjUH9ddXVXQWZUWEI=",
);

const app = createApp(App);

const pinia = createPinia();

pinia.use(UiStorePlugin);
pinia.use(RouterStorePlugin);

pinia.use(TripStorePlugin as any);

app.use(pinia);

app.use(Toast, { position: POSITION.BOTTOM_LEFT });

//SCROLL TOP BEFORE ENTERING EACH PAGE
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
app.use(router);
//SCROLL TOP BEFORE ENTERING EACH PAGE
app.use(i18n);

// app.use(IdlePlugin, {
//   timeout: 30000,
//   onIdle: () => {
//     const userStore = useUserStore();
//     if (userStore.isHotel) {
//       const uiStore = useUiStore();
//       uiStore.wallPaper.show = true;
//       const tripStore = useTripStore();
//       tripStore.reset();
//       router.replace("/");
//     }
//   },
// });

/* add icons to the library */
library.add(
  faPhone,
  faCircleXmark,
  faEllipsisVertical,
  faStar,
  faLocationDot,
  faFlagCheckered,
  faCar,
  faClock,
  faBars,
);

/* add font awesome icon component */
app.component("fa-icon", FontAwesomeIcon);

app.mount("#app");

app.component("DownloadExcel", JsonExcel);

datadogRum.init({
  applicationId: import.meta.env.VITE_DD_APP_ID,
  clientToken: import.meta.env.VITE_DD_APP_TOKEN,
  site: import.meta.env.VITE_DD_APP_SITE,
  service: import.meta.env.VITE_DD_APP_SERVICE,
  env: import.meta.env.VITE_MODE,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: import.meta.env.VITE_CLIENT_VERSION,
  sessionSampleRate: Number(import.meta.env.VITE_DD_SESSION_SAMPLE_RATE) || 0,
  sessionReplaySampleRate:
    Number(import.meta.env.VITE_DD_SESSION_REPLAY_SAMPLE_RATE) || 0,
  trackUserInteractions: import.meta.env.VITE_DD_TRACK_RESOURCES ? true : false,
  trackResources: import.meta.env.VITE_DD_TRACK_LONG_TASKS ? true : false,
  trackLongTasks: import.meta.env.VITE_DD_TRACK_USER_INTERACTIONS
    ? true
    : false,
  defaultPrivacyLevel:
    import.meta.env.VITE_DD_DEFAULT_PRIVACY_LEVEL || "mask-user-input",
  trackViewsManually: true,
});
