<script lang="ts">
import { RouterView, useRoute } from "vue-router";
import Navbar from "./components/Navbar.vue";
import Spinner from "./components/Spinner.vue";
import { useUiStore } from "./stores/ui";
import { useUserStore } from "./stores/user";
import { computed } from "vue";
import CookieBanner from "./components/AccessComponents/CookieBanner.vue";
import Wallpaper from "./views/Wallpaper.vue";

export default {
  /* data() {
      return {
        showToolbarAndHeader: false,
        loading: true,
        currentStep: null,
        language: null,
      };
    }, */
  setup() {
    const userStore = useUserStore();
    const uiStore = useUiStore();
    const route = useRoute();

    // Navbar nascosta se la route include /access
    const showNavbar = computed(() => {
      return !route.path.includes("/access");
    });

    return {
      userStore,
      uiStore,
      showNavbar,
    };
  },
  /* components: {
      Spinner,
    }, */
  /* watch: {
      $route: function (previous, current) {
        if (!this.nav.isIntroPage() && !this.nav.isLoginPage())
          this.showToolbarAndHeader = true;
        else this.showToolbarAndHeader = false;
  
        this.currentStep = this.$route.path;
      },
      // deep: true,
      // immediate: true,
    }, */
  methods: {
    initApp() {
      let config = {
        apiUrlBase: null,
        appTitle: null,
      };
      config.apiUrlBase = import.meta.env.VITE_API_URL_BASE || "";
      config.appTitle = import.meta.env.VITE_TITLE || "";
    },
    /* setLanguage() {
          if (!this.store.getLanguage()) {
            this.language =
              window.navigator.language || window.navigator["userLanguage"];
            this.language?.split("-")[0];
            this.store.setLanguage(this.language);
            this.$i18n.locale = this.language;
          } else {
            this.$i18n.locale = this.store.getLanguage();
            this.language = this.$i18n.locale;
          }
        }, */
    /* onChangeLanguage(lang) {
          this.language = lang;
        }, */
  },
  beforeMount() {
    /* this.loading = true; */
    this.initApp();
    /* this.setLanguage(); */
    /* this.loading = false; */
  },
  mounted() {},
  components: { Spinner, Navbar, RouterView, CookieBanner, Wallpaper },
};
</script>

<template>
  <Spinner :show="uiStore.globalSpinner.loading" />
  <Navbar v-if="showNavbar" />
  <RouterView />
  <Wallpaper v-if="userStore.isHotel && uiStore.wallPaper.show" />
  <CookieBanner />
</template>

<style scoped></style>
