import { localize, setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { createI18n } from "vue-i18n";
import { languages } from "../i18n";
import locales from "flatpickr/dist/l10n";
import { validatorsErrorsMsgs } from "../i18n/forms_validators";
import type { LANG_ISO_CODE } from "../constants/lang_iso_code";

export const getBrowserLocale = () => {
  return navigator.language || (<any>navigator).userLanguage;
};

export const getLocaleOnly = (isoLang: string) =>
  isoLang.split("-")[0].toUpperCase() as keyof typeof LANG_ISO_CODE;

export const getLocaleForFlatPickr = () => {
  const locale: string = getBrowserLocale();
  const [lang] = locale.split("-");
  if (lang) {
    return (locales as any)[lang as any] as typeof locales.en;
  }
  return locales.en;
};

export const getLocaleForFlatPickrUserPref = () => {
  const locale: string = i18n.global.locale.value || getBrowserLocale();
  const [lang] = locale.split("-");
  if (lang) {
    return (locales as any)[lang as any] as typeof locales.en;
  }
  return locales.en;
};

export const i18n = createI18n({
  locale: localStorage.getItem("favorite_language") || getBrowserLocale(),
  fallbackLocale: "en-GB",
  messages: languages,
  legacy: false,
  globalInjection: true,
  allowComposition: false,
});

configure({
  generateMessage: localize({
    en: {
      messages: {
        ...validatorsErrorsMsgs.en,
      },
    },
    it: {
      messages: {
        ...validatorsErrorsMsgs.it,
      },
    },
    fr: {
      messages: {
        ...validatorsErrorsMsgs.fr,
      },
    },
  }),
});

setLocale(localStorage.getItem("favorite_language") || getBrowserLocale());

export type i18n = typeof i18n;
