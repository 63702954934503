export function dateToDateTime(date: Date) {
  return (
    date.getFullYear() +
    "-" +
    ("00" + (date.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("00" + date.getUTCDate()).slice(-2) +
    "T" +
    ("00" + date.getUTCHours()).slice(-2) +
    ":" +
    ("00" + date.getUTCMinutes()).slice(-2) +
    ":" +
    ("00" + date.getUTCSeconds()).slice(-2)
  );
}

export function dateTimeToDate(dateTime: string) {
  const [date, time] = dateTime.split(" ");
  const [month, day, year] = date.split("/");
  // reformat string into YYYY-MM-DDTHH:mm:ss.sssZ
  try {
    return new Date(`${year}-${month}-${day}T${time}Z`);
  } catch (e) {
    return new Date(`${month}-${day}-${day}T${time}Z`);
  }
}

export function getUTC24HTimeString(date: Date) {
  return `${date.getUTCHours()}:${date
    .getUTCMinutes()
    .toString()
    .padStart(2, "0")}`;
}
