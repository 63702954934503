import type { NexiPagamentiCliente } from "../models/payment/nexi";
import Api, { parseApiResponse } from "./Api";

export default class PaymentApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL + "payment/";

  async getNextNexiProgressivo(customerId: number, idazienda = 1) {
    return parseApiResponse<number>(
      await this.get(this.baseApiUrl, "nextprogressivo", null, {
        idCliente: customerId.toString(),
        idazienda: idazienda.toString(),
      }),
    );
  }

  async getCards(customerId: number, idazienda = 1) {
    return parseApiResponse<Array<NexiPagamentiCliente>>(
      await this.get(this.baseApiUrl, "nexi/list", null, {
        idCliente: customerId.toString(),
        idazienda: idazienda.toString(),
      }),
    );
  }

  async deleteCard(cardId: number) {
    return parseApiResponse<Array<NexiPagamentiCliente>>(
      await this.get(this.baseApiUrl, `nexi/delete?idPagamento=${cardId}`),
    );
  }

  async updateCard(card: NexiPagamentiCliente) {
    return parseApiResponse<Array<NexiPagamentiCliente>>(
      await this.post(this.baseApiUrl, `nexi/update`, card),
    );
  }
}
